<template>
  <LayoutManager>
    <Loader v-if="isLoading"></Loader>
    <div class="h-100" v-else>
      <LxpBreadcrumb
        class="learning-space__breadcrumb"
        :items="items"
        @click="navigateToLink"
      ></LxpBreadcrumb>
      <div class="learning-space__error" v-if="hasError">
        <LearningSpace
          :hasError="true"
          @click-empty-space-cta="onEmptyStateCTA"
          :spaceObj="learningSpaceInfo"
        />
      </div>
      <div class="learning-space__wrapper" v-else>
        <div class="ls-wrapper__main">
          <LearningSpace
            :key="key"
            :spaceObj="learningSpaceInfo"
            :urlList="getURLList"
            :token="authToken"
            :initiatives="initiatives"
            :sections="sections"
            :tags="tags"
            :filterOptions="filterOptions"
            :featuredResources="featuredResources"
            @card-click="onCardClick"
            @click-empty-state-cta="onEmptyStateCTA"
          />
        </div>
      </div>
    </div>
  </LayoutManager>
</template>
<script>
import axios from "axios";
import { LxpBreadcrumb } from "didactica";
import { LearningSpace } from "didactica";
import LayoutManager from "../components/Layout/LayoutManager";
import { mapGetters } from "vuex";

export default {
  components: { LayoutManager, LearningSpace, LxpBreadcrumb },
  data() {
    return {
      items: [
        {
          icon: require("@/assets/images/menu/library-discovery.svg"),
          text: this.$t("general.menu.discovery"),
          to: { name: "course-discovery" }
        },
        {
          text: this.$route.params.org.toUpperCase(),
          active: true
        }
      ],
      isLoading: true,
      learningSpaceInfo: {},
      initiatives: [],
      featuredResources: [],
      sections: [],
      tags: [],
      filterOptions: [],
      key: 0,
      hasError: false,
      org: ""
    };
  },
  computed: {
    ...mapGetters(["allConfig", "language"]),
    authToken() {
      return `Bearer ${this.$keycloak.token}`;
    },
    currentOrg() {
      return this.$route.params.org;
    },
    getURLList() {
      return {
        LIST_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/discovery-paginated?org=${this.currentOrg}&language=${this.language}`
        },
        FILTER_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/tags/filter-paginated/?tags=[{tagids}]&language={lang}`
        },
        LIST_FEATURED_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/discovery-paginated?org=${this.currentOrg}&enforce_org_in_id=true`
        }
      };
    }
  },

  created() {
    this.org = this.$route.params.org;
    this.getLearningSpaceInfo();
  },
  methods: {
    getLearningSpaceInfo() {
      axios
        .get(`${this.allConfig.baseURL}/spaces/${this.org}/live/`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        })
        .then(res => {
          this.learningSpaceInfo = res.data;
          this.getSections();

          this.key++;
        })
        .catch(() => {
          this.isLoading = false;
          this.learningSpaceInfo = { name: this.currentOrg };
          this.hasError = true;
        });
    },
    getFeaturedResources() {
      axios
        .get(
          `${this.allConfig.baseURL}/spaces/${this.org}/courses/featured/live/`,
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`
            }
          }
        )
        .then(res => {
          this.featuredResources = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getInitiatives() {
      axios
        .get(`${this.allConfig.baseURL}/spaces/${this.org}/initiatives/live/`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        })
        .then(res => {
          this.initiatives = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getSections() {
      axios
        .get(`${this.allConfig.baseURL}/spaces/${this.org}/sections/live/`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        })
        .then(res => {
          this.sections = res.data;
          this.getInitiatives();
          this.getTags();
          this.getFeaturedResources();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getTags() {
      axios
        .get(`${this.allConfig.baseURL}/tags/live/?org=${this.org}`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        })
        .then(res => {
          this.tags = res.data.results;
          this.filterOptions = res.data.results;
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
        });
    },
    onCardClick(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c.id, from: "" },
        query: {
          source: c.source,
          org: this.currentOrg,
          from: "learning-space"
        }
      });
    },
    onEmptyStateCTA() {
      this.$router.push({ name: "course-discovery" });
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    }
  }
};
</script>
<style lang="scss" scoped>
.learning-space__wrapper {
  padding: 0px 32px;
  margin-left: 10px;
  .ls-wrapper__main {
    background: $brand-neutral-0;
  }
}
.learning-space__error {
  background: $brand-neutral-0;
  padding-top: 10px;
  margin: 0px 46px 0px 46px;
  border-radius: 8px;
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  .ls-wrapper {
    box-shadow: none;
    border-radius: 0px;
  }
}

.learning-space__breadcrumb {
  padding: 10px 0px 0px 20px;
  margin-left: 10px;
}
</style>
